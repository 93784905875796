
import './index.css'
import ReactTooltip from 'react-tooltip'
import { randomInt } from '../../Utils'

export default function Badges({ badges, style }){

    return (
        <>
            <ul className="badges" style={style}>
                {badges.map((badge,i) => (
                    <li key={i.toString()}>
                        <div className="badge">
                            <p className="text-badge">{badge}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export function Badge({ name, style, small = false, id = `tt_${randomInt(1000,9999)}` }){

    return (
        <>
            <a data-tip data-for={id} data-event="click">
                <div style={style} className={ small? "badge small" : "badge" }>
                    <p className="text-badge">{name}</p>
                </div>
            </a>
            <ReactTooltip id={id} type="dark">
                <span>Von der Redaktion empfohlen</span>
            </ReactTooltip>
        </>
    )
}
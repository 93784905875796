import { Route, Redirect } from 'react-router-dom'
import { useUserLocation } from '../location-context'

function MapRoute({ children, ...rest}) {
    const { userLocation } = useUserLocation()

    return (
        <Route
            {...rest}
            render={({location}) => userLocation !== null
            ? 
            children
            :
            <Redirect to={{ pathname: '/location', state: { from: location }}} />}
        />
    )
}

export default MapRoute
export const MAPBOX_APIKEY = "pk.eyJ1Ijoic21tNzYiLCJhIjoiY2t0eGRpemV0Mmc2djJ1bnFvdGJlNHRjaiJ9.VOzbh2hAD5wgFx0nUEdtHQ"

export const IPSTACK_APIKEY = "66c3def2380efd31da1431110ba81f30"

export const GOOGLE_CLIENT_ID = "641691376594-dn7c98e893e2f4lummuqiskbpjmrf1oq.apps.googleusercontent.com"

export const GOOGLE_APIKEY = "AIzaSyCq_xel15n6g2m0-EmvKX-KcxoFdYtNDOk"

export const VARIETIE_NAMES = {
    "minced": "Hackfleisch",
    "sliced": "Scheibenfleisch",
    "turkey": "Pute",
    "vegan": "vegan",
    "vegetarian": "vegetarisch",
    "grill_vegetables": "Grillgemüse",
    "open": "jetzt geöffnet",
    "best_rated": "bestbewertet"
}

export const RATING_NAMES = {
    "food": "Essen",
    "service": "Service",
    "ambience": "Ambiente",
    "variety": "Auswahl",
    "total": "Gesamt"
}
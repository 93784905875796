import icon from '../../doener.png'
import icon_dark from '../../doener_dark.png'

import './index.css'

export const LoadingSite = (props) => {
    return (
        <div style={{ paddingTop: '150px' }}>
            <Loading {...props}/>
        </div>
    )
}

function Loading({ style = null, width = '70px', dark = null }){

    return (
        <div style={style}>
            <img src={dark? icon_dark : icon} style={{width: width}} className="loading"/>
        </div>
    )
}

export default Loading


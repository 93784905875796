import { createContext, useContext, useState } from 'react'

const UserContext = createContext(useProvideUser)


export const useUser = () => useContext(UserContext)

function useProvideUser(){

    const [user, setUser] = useState(null)

    return {
        user,
        setUser
    }
}

const UserProvider = ({children}) => {
    const user = useProvideUser()

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
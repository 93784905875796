
import { useEffect, useRef, useState } from 'react'
import Headline from '../Headline'
import './index.css'

export default function RatingInput({ value = 0, readOnly = false, title = ""}){

    const inputRef = useRef()

    const [val, setVal] = useState(value)

    useEffect(() => {
        if(inputRef.current){
            inputRef.current.style = `${readOnly? '--fill:#777;' : ''}--value:${val.toFixed(1)}`
        }
    })

    function onChange(e){
        setVal(parseFloat(e.target.value))
    }

    useEffect(() => {
        setVal(value)
    }, [value])

    return (
        <div className="rating_input-wrapper">
            <label className="rating_input-label">
                {title && <Headline>
                    <strong>
                        {`${title} ${val.toFixed(1)}`}
                    </strong>
                </Headline>}
            {
            readOnly
            ?
            <input
                ref={inputRef}
                className="rating_input"
                max="5"
                readOnly={readOnly}
                // step={readOnly? "0.01":"0.5"}
                step={"0.01"}
                type="range"
                value={value} 
            />
            :
            <input
                ref={inputRef}
                className="rating_input"
                max="5"
                step={"0.5"}
                type="range"
                onChange={onChange}
                value={val} 
            />
            }
            </label>
        </div>
    )
}
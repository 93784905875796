import { useRef, useState } from "react"
import { FaBookmark, FaCalendar, FaCheck, FaComment, FaCommentAlt, FaHeart, FaHourglass, FaImage, FaImages, FaInfo, FaRoute, FaStar, FaUtensils } from "react-icons/fa"
import { useHistory, useLocation } from "react-router"
import Badges from "../../components/Badges"
import Headline from "../../components/Headline"
import { LoadingSite } from "../../components/Loading"
import { useData } from "../../data-context"

import ReactTooltip from "react-tooltip"

import './index.css'
import RatingRoute from "../Rating"
import { Link } from "react-router-dom"
import TabComponent, { TabContent } from "../../components/Tabs"

function StoreDetail({ match }){

    const { getItem, saveToFavorites, isFavorite, removeFromFavorites } = useData()

    let id = match? match.params.id : null
    
    const { push } = useHistory()

    let item = getItem(id)

    const [isFav, setIsFav] = useState(isFavorite(id))
    const linkToMaps = useRef()

    if(item){
        const { name = "", title, location = { city: "" }, badges = [], verified, cover, rating } = item 
        const { city = "", coords } = location
        const { lat, lng } = coords
    
        function remove(){
            removeFromFavorites(id)
            setIsFav(isFavorite(id))
        }

        function add(){
            saveToFavorites(id)
            setIsFav(isFavorite(id))
        }

        return (
            <>
                <div className="store-bg" style={{ backgroundImage: `url(${cover})` }}>
                    <div className="inner">
                        <div className="" style={{ maxWidth: '992px', margin: 'auto' }}>
                            <div className="vfw">
                                <Headline>
                                    <h1>
                                        {title}
                                    </h1>
                                </Headline>
                                <span className="verified_wrapper" title={verified ? "verifiziert" : "Verifizierung ausstehend"}>
                                    <a data-tip data-for="verified" data-event="click">
                                        {verified
                                            ?
                                            <span className="verified">
                                                <FaCheck />
                                            </span>
                                            :
                                            <span className="pending">
                                                <FaHourglass />
                                            </span>
                                        }
                                    </a>
                                </span>
                                <ReactTooltip id="verified" type={verified ? "dark" : "info"}>
                                    {verified
                                        ?
                                        <span>
                                            Die Echtheit dieses Geschäfts kann bestätigt werden
                                        </span>
                                        :
                                        <span>
                                            Die Echtheit dieses Geschäfts kann noch nicht bestätigt werden.
                                        </span>
                                    }
                                </ReactTooltip>
                            </div>
                            {name !== title && <Headline>
                                <span>
                                    {name}
                                </span>
                            </Headline>}
                            <Headline>
                                <h2>{city}</h2>
                            </Headline>
                            {isFav
                                ?
                                <button onClick={remove} className="btn-secondary">
                                    <FaHeart size={10} style={{ marginRight: "5px" }} />
                                    Aus Favoriten entfernen
                                </button>
                                :
                                <button onClick={add} className="btn-secondary">
                                    <FaHeart size={10} style={{ marginRight: "5px" }} />
                                    Zu Favoriten hinzufügen
                                </button>
                            }

                            <a ref={linkToMaps} href={`http://maps.google.com/maps?q=${lat},${lng}`} target="_blank" rel="noopener">
                                <button onClick={() => {
                                    if(linkToMaps.current){ 
                                        linkToMaps.current.click()
                                    }
                                }} className="btn-primary open-maps">
                                    <FaRoute /> Route
                                </button>
                            </a>


                                <div style={{ position: 'relative' }}>
                                    <Badges badges={badges} style={{ bottom: "unset", top: "-1rem" }} />
                                    <Link to={`/store/${id}/detail/rating`}>
                                        <div className="rating" style={{ fontSize: "1.3rem", top: "1rem" }}>
                                            <FaStar />
                                            <span>{rating.total?.toFixed(1)}</span>
                                        </div>
                                    </Link>
                                </div>
                        </div>
                    </div>

                </div>
                <div className="inner">
                    <div>
                        <p></p>
                        <Headline>
                            <h4>
                                Du warst bei {title} essen?
                            </h4>
                        </Headline>
                        <button onClick={() => push({ pathname: `/store/${id}/detail/rating` })} className="btn-secondary">
                            Jetzt bewerten
                        </button>
                    </div>

                    <TabComponent tabs={[
                        {
                            title: 'Öffnungszeiten',
                            id: 'opening_hours',
                            icon: <FaCalendar />,
                            color: "black",
                            content: TabContent
                        },
                        {
                            title: 'Speisekarte',
                            id: 'menu',
                            icon: <FaUtensils />,
                            color: "black",
                            content: TabContent
                        },
                        {
                            title: 'Bewertungen',
                            id: 'ratings',
                            icon: <FaCommentAlt />,
                            color: "black",
                            content: TabContent
                        },
                        {
                            title: 'Bilder',
                            id: 'pictures',
                            icon: <FaImage />,
                            color: "black",
                            content: TabContent
                        },
                        {
                            title: 'Info',
                            id: 'info',
                            icon: <FaInfo />,
                            color: "orange",
                            content: TabContent
                        }
                    ]} />

                    {!verified
                        &&
                        <>
                            <Headline>
                                <h4>
                                    Dir gehört dieses Geschäft?
                                </h4>
                            </Headline>
                            <button className="btn-secondary">
                                Als Eigentümer melden
                            </button>
                        </>
                    }
                    <p>
                        <br/>
                    </p>
                </div>

                <RatingRoute base={`/store/:id/detail`} />
            </>
        )
    }else{
        return <LoadingSite dark />
    }
}

export default StoreDetail
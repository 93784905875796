import { useState } from 'react'
import { FaBookmark, FaBug, FaChevronLeft, FaEnvelope, FaHeart, FaInfo, FaListAlt, FaMap, FaMapMarker, FaMapMarkerAlt, FaPlus, FaSearch, FaStar, FaStoreAlt } from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router-dom'
import { useData } from '../../data-context'
import { useUserLocation } from '../../location-context'
import { useUser } from '../../user-context'
import Avatar from '../Avatar'
import Headline from '../Headline'
import Loading from '../Loading'
import styles from './index.css'

function Header(){

    const { user } = useUser()
 
    const { isLoading } = useData()

    const { push, goBack } = useHistory()

    const location = useLocation()

    const { initialPlaceName } = useUserLocation()

    const [menuOpen, setMenuOpen] = useState(false)

    function toggleMenu(){
        setMenuOpen(!menuOpen)
    }

    function goTo(path, e){
        e.preventDefault()
        push({
            pathname: path
        })
        if(menuOpen){
            toggleMenu()
        }
    }

    return (
        <>
            <nav>
                {isLoading && <Loading style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '1rem' }} width="45px"/>}
                {location.pathname !== "/"
                &&
                <button className="go-back" onClick={goBack} title="Zurück">
                    <FaChevronLeft />
                </button>
                }
                <Headline>
                    <h1>
                        <a href="#" onClick={e => goTo("/", e)}>
                            DÖNER-MAP
                        </a>
                    </h1>
                </Headline>
                <button onClick={toggleMenu} className={menuOpen? 'btnToggleMenu active':'btnToggleMenu'}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </nav>
            <aside className={menuOpen? "open" : ""}>
                <div className="inner">
                    <div className="flex-space">
                        <div>
                            <a href="#" onClick={e => goTo("/location", e)}>
                                <Headline>
                                    <h3 className="loc"><FaMapMarkerAlt size={14} style={{ marginRight: "5px" }}/> <span>{initialPlaceName.current || "Kein Ort"}</span></h3>
                                </Headline>
                            </a>
                        </div>
                        <div style={{ position: 'relative' }}>
                            {user
                            ?
                            <button onClick={(e) => goTo('/signout', e)} style={{ fontSize: "1.1rem" }} className="btn-secondary">
                                Sign Out
                            </button>
                            :
                            <button onClick={(e) => goTo('/signin', e)} style={{ fontSize: "1.1rem" }} className="btn-secondary">
                                Sign In
                            </button>
                            }
                            <button style={{ fontSize: "1.1rem" }} className="btn-primary">
                                <FaInfo size={10} />
                                Info
                            </button>
                            {user
                            &&
                            <div style={{ position: "absolute", top: "50px", right: "0px", display: "flex", justifyContent: 'center', flexDirection: 'column' }}>
                                <Avatar src={user.imageUrl} />
                                <Headline>
                                    <h5 style={{marginTop: "5px"}}>{user.givenName}</h5>
                                </Headline>
                            </div>    
                            }
                        </div>
                    </div>
                    <ul>
                        {location.pathname !== "/"
                        &&
                        <li>
                            <button onClick={(e) => goTo('/', e)} className="btn-primary">
                                <FaMap size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Map
                            </button>
                        </li>}
                        <li>
                            <button className="btn-primary">
                                <FaSearch size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Suche
                            </button>
                        </li>
                        <li>
                            <button className="btn-primary">
                                <FaStar size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Featured
                            </button>
                        </li>
                        <li>
                            <button onClick={(e) => goTo('/favorites', e)} className="btn-primary">
                                <FaHeart size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Deine Favoriten
                            </button>
                        </li>
                        <li>
                            <button onClick={(e) => goTo('/list', e)} className="btn-primary">
                                <FaListAlt size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Listenansicht
                            </button>
                        </li>
                        <li>
                            <button onClick={(e) => goTo('/create', e)} className="btn-primary">
                                <FaPlus size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Geschäft hinzufügen
                            </button>
                        </li>
                        <li>
                            <button className="btn-primary">
                                <FaBug size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Fehler melden
                            </button>
                        </li>
                        <li>
                            <button className="btn-primary">
                                <FaEnvelope size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Feedback
                            </button>
                        </li>
                        <li>
                            <button className="btn-secondary">
                                <FaStoreAlt size={14} style={{ marginTop: "-5px", marginRight: "5px" }} />
                                Ich habe ein Restaurant
                            </button>
                        </li>
                    </ul>

                </div>
                <div className="claim">
                    <Headline>
                        <h4>
                            'CAUSE DÖNER IS <FaHeart color="red" style={{ marginTop: '5px', fontSize: ".9em" }} />
                        </h4>
                    </Headline>
                </div>
            </aside>
        </>
    )
}

export default Header
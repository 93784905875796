import { createContext, useContext, useEffect, useRef, useState } from 'react'

import json from './data.json'
import { useUserLocation } from './location-context'
import { distanceInKmBetweenEarthCoordinates } from './Utils'

const DataContext = createContext(useProvideData)

export const useData = () => useContext(DataContext)

const computeRating = (rating) => {
    let avg = 0
    const length = Object.keys(rating).length
    for(let category in rating){
        // weight food and variety more
        if(category === "food"){
            avg += rating[category]*3
        }else if(category === "variety"){
            avg += rating[category]*1.5
        }else{
            avg +=  rating[category]
        }
    }
    return avg/(length+2.5)
}

function useProvideData(){

    for(let i=0;i<json.length;i++){
        json[i].rating.total = computeRating(json[i].rating)
    }

    const [data, setData] = useState(json)

    const [radius, _setRadius] = useState(30)

    const [appliedFilters, setAppliedFilters] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const { userLocation } = useUserLocation()

    useEffect(() => {
        // applyFilter(appliedFilters)
        // setTimeout(() => {
        //     // set initial data
        // }, 2500)
    }, [])

    const getOpened = (_data = json) => {
        const today = new Date()
        const now = today.toTimeString().substr(0,5)
        const weekday = today.getDay()
        const opened = _data.filter(d => {
            if(d.openingHours[weekday]){
                const { from, to } = d.openingHours[weekday]
                const prevDay = d.openingHours[weekday-1 >= 0? weekday-1:0]
                const open = prevDay && prevDay.to > now && prevDay.from > prevDay.to? now <= prevDay.to : now > to && to < from && now >= from? true : now >= from && now <= to
                return open
            }
            return false
        })
        return opened
    }

    const toggleFilter = (filter, dataSource) => {
        let _filters = appliedFilters
        if(!(filter instanceof Array)){
            filter = [filter]
        }
        filter.forEach((f) => {
            const index = _filters.indexOf(f)
            if(index > -1){
                _filters.splice(index, 1)
            }else{
                _filters.push(f)
            }
        })
        setAppliedFilters([..._filters])
        setData(applyFilter([..._filters]))

        // special data source (e.g. favorites)
        if(dataSource){
            const res = applyFilter([..._filters], dataSource, false)
            return res
        }
    }

    const applyFilter = (filter = [], _data, _filterByRadius = true) => {
        if(!_data){
            _data = json
        }
        if(!filter) return _data
        if(filter.length > 0){
            const _filter = [...filter]
            if(filter.includes('open')){
                _data = getOpened(_data)
                const index = _filter.indexOf('open')
                _filter.splice(index, 1)
            }
            
            if(filter.includes('best_rated')){
                const index = _filter.indexOf('best_rated')
                _filter.splice(index, 1)
            }

            var filtered = _data.filter(d => {
                if(_filter.length === 0) return true
                for(let i=0;i<_filter.length;i++){
                    if(d.varieties.indexOf(_filter[i]) === -1){
                        return false
                    }
                }
                return true
            })

            if(filter.includes('best_rated')){
                filtered.sort((a,b) => b.rating.total - a.rating.total)
            }
            return _filterByRadius? filterByRadius([...filtered]) : [...filtered]
        }else{
            return _filterByRadius? filterByRadius([..._data]) : [..._data]
        }
    }

    const filterByRadius = (_data, r = radius) => {
        if(!userLocation) return _data
        const filtered = _data.filter(d => {
            const { lat, lng } = userLocation
            const to = d.location.coords
            return distanceInKmBetweenEarthCoordinates(lat,lng,to.lat,to.lng) <= r
        })
        return filtered
    }

    const setLoading = (loading = false) => setIsLoading(loading)

    const isOpen = (id) => {
        const item = getItem(id)
        if(item){
            const today = new Date()
            const now = today.toTimeString().substr(0,5)
            const weekday = today.getDay()
            if(item.openingHours[weekday]){
                const { from, to } = item.openingHours[weekday]
                const nextDay = item.openingHours[weekday+1 < 7? weekday+1:0]
                const prevDay = item.openingHours[weekday-1 >= 0? weekday-1:0]
                const open = prevDay && prevDay.to > now && prevDay.from > prevDay.to? now <= prevDay.to : now > to && to < from && now >= from? true : now >= from && now <= to

                const date2 = new Date()
                const temp = to.split(":")
                const hours = parseInt(temp[0]), minutes = parseInt(temp[1])
                date2.setHours(hours)
                date2.setMinutes(minutes)
                const diff = Math.floor((date2.getTime() - Date.now())/(1000*60))
                const closesIn = open && diff > 0 && diff <= 60? diff : null

                return {
                    open,
                    next: open? prevDay && prevDay.to > now && prevDay.from > prevDay.to? prevDay.to : to :  now < from? from : nextDay?.from || null,
                    nextDay: now > from? "Morgen" : "",
                    closesIn
                }
            }else{
                const nextDay = item.openingHours[weekday+1 < 7? weekday+1:0]
                return {
                    open: false,
                    next: nextDay?.from || null,
                    nextDay: nextDay? 'Morgen' : ''
                }
            }
        }
        return {
            open: false
        }
    }

    const getItem = (id) => {
        if(json){
            const index = json.findIndex(d => d.id.toString() === id.toString())
            if(index > -1){
                return json[index]
            }
        }
        return null
    }
    

    const setRadius = (radius) => {
        _setRadius(radius)
    }

    useEffect(() => {
        if(userLocation){
            setData(applyFilter(appliedFilters))
        }
    }, [radius, userLocation])


    const saveToFavorites = (id) => {
        let favorites = localStorage.getItem('favorites')
        favorites = favorites? JSON.parse(favorites) : []
        if(!isFavorite(id)){
            favorites.push(id)
            localStorage.setItem('favorites', JSON.stringify(favorites))
            return true
        }
        return false
    }

    const getFavorites = () => {
        let favorites = localStorage.getItem('favorites')
        const result = []
        if(!favorites) return result
        favorites = JSON.parse(favorites)
        for(let i in favorites){
            const item = getItem(favorites[i])
            if(item){
                result.push(item)
            }
        }
        return result
    }

    const removeFromFavorites = (id = 0) => {
        let favorites = localStorage.getItem('favorites')
        if(!favorites) return false
        favorites = JSON.parse(favorites)
        const index = favorites.indexOf(id.toString())
        if(index > -1){
            favorites.splice(index, 1)
            localStorage.setItem('favorites', JSON.stringify(favorites))
            return true
        }
        return false
    }

    const isFavorite = (id) => {
        let favorites = localStorage.getItem('favorites')
        if(!favorites || !id) return false
        return favorites.includes(id.toString())
    }

    return {
        data,
        getItem,
        getOpened,
        isOpen,
        isLoading,
        setLoading,
        applyFilter,
        appliedFilters,
        toggleFilter,
        radius,
        setRadius,
        saveToFavorites,
        removeFromFavorites,
        getFavorites,
        isFavorite
    }
}

const DataProvider = ({children}) => {
    const data = useProvideData()

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    )
}

export default DataProvider
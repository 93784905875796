import { PUBLIC_HOLIDAYS } from "./PUBLIC_HOLIDAYS";

const degreesToRadians = (degrees) => {
    return degrees * Math.PI / 180
}

export const distanceInKmBetweenEarthCoordinates = (lat1, lon1, lat2, lon2) => {
    const earthRadiusKm = 6371;
  
    var dLat = degreesToRadians(lat2-lat1);
    var dLon = degreesToRadians(lon2-lon1);
  
    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);
  
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return earthRadiusKm * c;
}

export const getBearing = (lat1, lng1, lat2, lng2) => {
    const { sin, cos, PI, atan2 } = Math
    const dLng = degreesToRadians(lng2 - lng1)
    lat1 = degreesToRadians(lat1)
    lat2 = degreesToRadians(lat2)
    lng1 = degreesToRadians(lng1)
    lng2 = degreesToRadians(lng2)
    const y = sin(dLng) * cos(lat2)
    const x = cos(lat1) * sin(lat2) - sin(lat1) * cos(lat2) * cos(dLng)
    const bearing = atan2(y,x)/(PI/180)
    return bearing < 0? bearing+360 : bearing
}

export const formatCurrency = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)

export const randomInt = (min, max) => Math.floor(Math.random() * (max-min+1)) + min

export const calcTextWidth = ({ text, font = 'sans-serif', fontSize = '25px', fontStyle = 'bold' }) => {
    var el = document.getElementById("calcTextWidth")
    if(!el){
        el = document.createElement("el")
        el.id = "calcTextWidth"
        el.style = 'position: fixed; top: 0; left: 0; width: auto; visibility: hidden;z-index:1;'
        document.body.appendChild(el)
    }
    el.style.font = font
    el.style.fontSize = fontSize
    el.style.fontStyle = fontStyle
    el.innerHTML = text
    const width = el.clientWidth
    return Math.ceil(width)
}

export const isPublicHoliday = (opts = { countryCode: "DE" }) => {
    const date = new Date().toISOString().split("T")[0]
    const year = new Date().getFullYear()
    const thisYear = PUBLIC_HOLIDAYS[year]
    if(thisYear){
        const index = thisYear.findIndex(el => el.date === date)
        if(index > -1){
            const { localName } = thisYear[index]
            return {
                isHoliday: true,
                localName
            }
        }
    }
    return {
        isHoliday: false
    }
}

export const refreshTokenSetup = (res) => {
    // Timing to renew access token
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  
    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      console.log('newAuthRes:', newAuthRes);
      // saveUserToken(newAuthRes.access_token);  <-- save new token
      localStorage.setItem('authToken', newAuthRes.id_token);
  
      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming);
    };
  
    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
  };
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { distanceInKmBetweenEarthCoordinates, getBearing } from './Utils'
import { IPSTACK_APIKEY, MAPBOX_APIKEY } from './VARS'

const LocationContext = createContext(useProvideLocation)

function loadLocation(){
    const location = localStorage.getItem('location')
    if(location){
        return JSON.parse(location)
    }
    return null
}

export const useUserLocation = () => useContext(LocationContext)

function useProvideLocation(){
    const [userLocation, _setUserLocation] = useState(loadLocation())
    const [placeName, setPlaceName] = useState("")
    const gpsLocation = useRef(userLocation)
    const initialPlaceName = useRef("")

    const map = useRef()

    const requestPlace = useRef(true)

    const setUserLocation = (location) => {
        _setUserLocation(location)
    }

    const deleteUserLocation = () => {
        _setUserLocation(null)
        gpsLocation.current = null
        initialPlaceName.current = ""
        localStorage.removeItem('location')
    }    

    const setMap = (m) => map.current = m

    const requestLocation = () => new Promise((resolve, reject) => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(async ({ coords }) => {
                const { latitude, longitude } = coords
                const location = { lat: latitude, lng: longitude }
                gpsLocation.current = location
                localStorage.setItem('location', JSON.stringify(location))
                setUserLocation(location)
                resolve(location)
            }, reject)
        }else{
            reject('geolocation not available')
        }
    })

    const locationByIp = () => new Promise(async (resolve, reject) => {
        try {
            const date = new Date()
            const res = await fetch(`http://api.ipstack.com/check?access_key=${IPSTACK_APIKEY}&format=1&ts=${date.getFullYear()}-${date.getMonth()}`, { cache: 'force-cache' })
            const json = await res.json()
            const { latitude, longitude, city } = json
            requestPlace.current = false
            const coords = {
                lat: latitude,
                lng: longitude
            }
            localStorage.setItem('location', JSON.stringify(coords))
            gpsLocation.current = coords
            setUserLocation(coords)
            initialPlaceName.current = city
            setPlaceName(city)
        } catch (error) {
            console.error(error)
            setUserLocation({
                lat: 48.491056867264916,
                lng: 9.206612934182065
            })
            setPlaceName("Reutlingen")
        }finally{
            requestPlace.current = true
            resolve()
        }
    })

    useEffect(() => {
        async function _getPlace(){
            try {
                const { lat, lng } = userLocation
                const place = await getPlace({ lat: lat, lng: lng })
                if(!initialPlaceName.current){
                    initialPlaceName.current = place
                }
                setPlaceName(place)
            } catch (error) {
                
            }
        }
        if(userLocation){
            if(!gpsLocation.current){
                gpsLocation.current = userLocation
            }
            if(requestPlace.current){
                _getPlace()
            }
        }
    }, [userLocation])

    // TODO use acutal position or map center to calculate distance?
    const calcDistance = ({ to, useMapLocation = false }) => {
        const location = useMapLocation? userLocation : gpsLocation.current
        if(location && to){
            const { lat, lng } = location
            return distanceInKmBetweenEarthCoordinates(lat,lng,to.lat,to.lng).toFixed(2)
        }
        return null
    }

    const calcBearing = ({ to, useMapLocation = false }) => {
        const location = useMapLocation? userLocation : gpsLocation.current
        if(location && to){
            const { lat, lng } = location
            return getBearing(lat, lng, to.lat, to.lng)
        }
        return null
    }

    const getPlace = async (coords) => {
        let place = ""
        try {
            const { lat, lng } = coords
            const res = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPBOX_APIKEY}&types=place`, { cache: "force-cache" })
            const json = await res.json()
            const {features} = json
            if(features[0]){
                place = features[0].text
            }
        } catch (error) {
            console.error(error)
        }finally{
            return place
        }
    }


    return {
        userLocation,
        placeName,
        setUserLocation,
        requestLocation,
        deleteUserLocation,
        calcDistance,
        calcBearing,
        initialPlaceName,
        locationByIp,
        map,
        setMap,
        gpsLocation,
    }
}

const LocationProvider = ({children}) => {
    const userLocation = useProvideLocation()

    return (
        <LocationContext.Provider value={userLocation}>
            {children}
        </LocationContext.Provider>
    )
}

export default LocationProvider
import Modal from 'react-modal';
 

import './animated.css'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    // marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    minWidth              : '50%',
    maxWidth: '90%',
    maxHeight             : '70%',
    textAlign: 'center',
    borderRadius: '25px',
    backgroundColor: 'var(--primary-dark)',
    border: 'none'
  },
  overlay: {
    zIndex: 199,
    background: 'rgba(0,0,0,.65)'
  }
}

function CustomModal(props){

    return (
      <Modal {...props} closeTimeoutMS={300} style={{ content: { ...customStyles.content, ...props.style}, overlay: { ...customStyles.overlay } }} />
    )
}

export default CustomModal
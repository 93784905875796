import { AnimateSharedLayout } from 'framer-motion';
import React, { lazy, Suspense } from 'react';
import {
  // HashRouter as Router,
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom"

import './App.css';
import Header from './components/Header';
import Loading from './components/Loading';
import DataProvider from './data-context';
import LocationProvider from './location-context';
import CreateRoute from './Protected/Create';
import MapRoute from './Protected/Map';
import StoreDetail from './Routes/StoreDetail';
import UserProvider from './user-context';
import { randomInt } from './Utils';

import { ErrorBoundary } from 'react-error-boundary'

const Map = lazy(() => import('./Routes/Map'))
// const Map = lazy(() => {
//   return new Promise(resolve => setTimeout(resolve, 5000)).then(
//     () => import("./Routes/Map")
//   );
// })
const Location = lazy(() => import('./Routes/Location'))
const Favorites = lazy(() => import('./Routes/Favorites'))
const List = lazy(() => import('./Routes/ListView'))
const Create = lazy(() => import('./Routes/Create'))
const SignIn = lazy(() => import('./Routes/Signin'))
const SignOut = lazy(() => import('./Routes/Signout'))



const loadingTexts = ["Der Gerät wird nie müde...", "Döner macht schöner!", "Der Gerät schläft nie ein...", "Der Gerät ist immer vor der Chef in Geschäft..."]

function ErrorFallback({ error, resetErrorBoundary }){

  return (
    <div style={{
      margin: "1rem auto",
      padding: "1rem",
      background: "red",
      color: "white"
    }}>
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button className="btn-primary" onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function Routes(){
  const location = useLocation()

  const text = loadingTexts[randomInt(0,loadingTexts.length-1)]

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          console.error("error")
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }}
      >
        <Suspense fallback={<div className="inner" style={{color: "black"}}><p></p><Loading dark/><h2>{text}</h2></div>}>
          <Switch location={location}>
            <Route path={["/signin", "/google-auth"]}>
              <SignIn />
            </Route>
            <Route path="/signout">
              <SignOut />
            </Route>
            <CreateRoute path="/create">
              <Create />
            </CreateRoute>
            <MapRoute path="/list">
              <List />
            </MapRoute>
            <Route path="/favorites">
              <Favorites />
            </Route>
            <Route path="/location">
              <Location />
            </Route>
            <Route path={["/store/:id/detail"]} component={StoreDetail} />
            <MapRoute path="/">
              <Map />
            </MapRoute>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

function App() {
  return (
    <div className="App">
      <UserProvider>
        <LocationProvider>
          <DataProvider>
            <div className="content">
              <AnimateSharedLayout type="crossfade">
                <Router>
                  <Header />
                  <Switch>
                    <Route path="*">
                      <Routes />
                    </Route>
                  </Switch>
                </Router>
              </AnimateSharedLayout>
            </div>
          </DataProvider>
        </LocationProvider>
      </UserProvider>
    </div>
  );
}

export default App;

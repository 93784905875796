import { Route, Redirect } from 'react-router-dom'
import { useUser } from '../user-context'

function CreateRoute({ children, ...rest}) {
    const { user } = useUser()

    return (
        <Route
            {...rest}
            render={({location}) => user !== null
            ? 
            children
            :
            <Redirect to={{ pathname: '/signin', state: { from: location }}} />}
        />
    )
}

export default CreateRoute
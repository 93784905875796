import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Route, Switch, useHistory, useLocation } from "react-router";
import CustomModal from "../../components/CustomModal";
import Headline from "../../components/Headline";
import RatingInput from "../../components/RatingInput";
import { useData } from "../../data-context";
import { useUser } from "../../user-context";
import { RATING_NAMES } from "../../VARS";

export function Rating({ match }){
    const { getItem } = useData()
    const id = match? match.params.id : null
    const { push, goBack } = useHistory()
    const { user } = useUser()

    const item = getItem(id)
    const {rating = {}, name = "", title = ""} = item 
    const { total = 0 } = rating

    const [editRating, setEditRating] = useState(false)

    const [readOnly, setReadOnly] = useState(true)

    useEffect(() => {
        if(user){
            setReadOnly(!editRating)
        }
    }, [editRating])    

    return (
        <CustomModal isOpen={true} onRequestClose={goBack}>
            <Headline>
                <h2>{title} bewerten</h2>
            </Headline>
            {/* {Object.keys(rating).map((key, i) => (
                <div key={i.toString()}>
                    <RatingInput value={rating[key]} title={RATING_NAMES[key]} readOnly />
                </div>
            ))} */}
            <RatingInput value={rating["total"]} title={RATING_NAMES["total"]} readOnly />
            <RatingInput value={readOnly? rating["food"] : 2.5} title={RATING_NAMES["food"]} readOnly={readOnly} />
            <RatingInput value={readOnly? rating["variety"] : 2.5} title={RATING_NAMES["variety"]} readOnly={readOnly} />
            <RatingInput value={readOnly? rating["service"] : 2.5} title={RATING_NAMES["service"]} readOnly={readOnly} />
            <RatingInput value={readOnly? rating["ambience"] : 2.5} title={RATING_NAMES["ambience"]} readOnly={readOnly} />
            {user
            ?
            !editRating?
            <button className="btn-primary block" onClick={() => setEditRating(true)}>
                Rate now
            </button>
            :
            <>
                <button className="btn-primary">
                    submit rating
                </button>
                <button className="btn-secondary" onClick={() => setEditRating(false)}>
                    cancel
                </button>
            </>
            :
            <p>
                Sign In to rate a place!
                <button onClick={() => { push({ pathname: '/signin' }) }} className="btn-primary block">
                    Sign In
                </button>
            </p>}
        </CustomModal>
    )
}

export default function RatingRoute({ base }){

    return (
        <Switch>
            <Route path={`${base}/rating`} component={Rating} />
        </Switch>
    )
}
import './index.css'
function Headline({ children }){
    
    return (
        <div className="__headline">
            {children}
        </div>
    )
}

export default Headline